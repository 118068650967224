import { Button, Card, Col, Descriptions, Form, Input, notification, Row, Skeleton, Popconfirm, Typography, Collapse, PageHeader } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, {useState, useEffect} from 'react'
import { CheckCircleOutlined } from '@ant-design/icons';
import dogecoinRegex  from 'dogecoin-regex'
import isValid from 'nano-address-validator';
import Search from 'antd/lib/input/Search';
import { useNavigate } from 'react-router-dom'
import { verify } from 'bitcoinjs-message'

const { Panel } = Collapse;

const checkDogecoinAddress = (address) => {
  if (!dogecoinRegex({exact: true}).test(address)) {
      return Promise.reject(new Error('Please enter a valid Dogecoin address!'));
  }
  else {
      return Promise.resolve();
  }
};

const checkDogeNanoAddress = (address) => {
  try {
    if (!isValid(address, 'xdg') ) {
        return Promise.reject(new Error('Please enter a valid DogeNano address!'));
    }
    else {
        return Promise.resolve();
    }
  } catch (error) {
    return Promise.reject(new Error('Please enter a valid DogeNano address!')); 
  }
};

const Success = ({address, hash, amountSent }) => {
  if (address === null){
    return ''
  }
  return <>
      <div style={{ maxWidth: "350px", marginRight: "auto", marginLeft: "auto" }}>
      <h1 style={{ textAlign: "center" }}><CheckCircleOutlined style={{ color: "#4BB543", marginRight: 4 }} />Successful claim !</h1>
          <Descriptions size='small' bordered>
              <Descriptions.Item span={12} label="Amount sent">{amountSent} XDG</Descriptions.Item>
              <Descriptions.Item span={12} label="Sent to address">{address}</Descriptions.Item>
              <Descriptions.Item span={12} label="View TX in explorer"><a target="_blank" rel="noreferrer" href={"https://explorer.dogenano.io/block/" + hash} >explorer.dogenano.io</a></Descriptions.Item>
          </Descriptions>
      </div>
  </>
}

function Claim() {
    const [dogecoinAddress, setDogecoinAddress] = useState(null)
    const [dogenanoAddress, setDogenanoAddress] = useState(null)
    const [loading, setLoading] = useState(false)
    const [dogeBalanceLoading, setDogeBalanceLoading] = useState(false)
    const [dogeBalance, setDogeBalance] = useState(null)
    const [messageToSign, setMessageToSign] = useState(null)
    const [signature, setSignature] = useState("")
    const [isSignatureValid, setIsSignatureValid] = useState(false)
    const [result, setResult] = useState({address: null, hash: null, amountSent: null})
    let navigate = useNavigate();
    
    const message_start = "snapshot 4/20/22 send dogenano to"
    useEffect(() => {
      if (dogenanoAddress !== null && isValid(dogenanoAddress, 'xdg') && dogecoinAddress !== null && dogecoinRegex({exact: true}).test(dogecoinAddress)){
        setMessageToSign(`${message_start} ${dogenanoAddress}`)
      }
      else{
        setMessageToSign(null)
      }
    }, [dogenanoAddress, dogecoinAddress])
    

    const openError = (message) => {
      notification.error({
        description: message,
        duration: 5
      });
   };
   const checkSignature = () => {
    console.log(signature);
       let messageToSign = "snapshot 4/20/22 send dogenano to " + dogenanoAddress
       try {
           let signatureValid = verify(messageToSign, dogecoinAddress, signature, "\x19Dogecoin Signed Message:\n")
           if (signatureValid){
             return Promise.resolve();
           }
           else{
             return Promise.reject(new Error('Please enter a valid Signature! See FAQ below.'));
           }
       } catch (error) {
         return Promise.reject(new Error('Please enter a valid Signature! See FAQ below.'));
       }
   };

   const onFinish = (values) => {
    console.log('Success:', values);
      if (dogeBalance <= 0){
        openError('This Dogecoin address had 0 Doge on block 4,191,928 or has already claimed XDG')
        return
      }
        // if (!isSignatureValid) return
        setLoading(true)
      //  fetch(process.env.NEXT_PUBLIC_ROOT_API + '/claim', {
       fetch('https://api.dogenano.io'+ '/claim', {
            method: 'POST', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                dogecoinAddress: dogecoinAddress,
                dogenanoAddress: dogenanoAddress,
                signature: signature,
            })
        })
        .then((r) => r.json())
        .then((r) => {
            if (r.error !== undefined) {
                    openError(r.error)
              }
              else {
                    setResult(r)
              }
                setLoading(false)
          })
  };
  const onFinishFailed = (errorInfo) => {
    // openError(r.error)

    console.log('Failed:', errorInfo);
  };

   
  return (
    <>
    {/* <Typography.Title level={2} style={{ color: "#333", marginTop: 32, marginBottom: 32, marginRight: "auto", marginLeft: "auto", textAlign: "center"}}>DogeNano Airdrop to Dogecoin Holder</Typography.Title> */}
    <PageHeader
    ghost={false}
    className="site-page-header"
    onBack={() => navigate('/')}
    title=<span style={{color: "#333"}}>Airdrop to Dogecoin Holders</span>
    // subTitle="This is a subtitle"
  />
    <Card style={{marginTop: 16}} >
{
  result.address !== null ? 
      <Success hash={result.hash} amountSent={result.amountSent} address={result.address} /> : 


      <Form 
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
      <div style={{marginBottom: 32, textAlign:"justify"}}>
      <p>

      The Dogecoin snapshot has been taken at block <b>4,191,928</b>, on 4/20/22 at 4:20 pm.
      <br/>
      1 DOGE gives 1 XDG.
      <br/>
      Enter your Dogecoin address below to see how much DogeNano you can claim.
      </p>
      </div>
      {/* <Form.Item label="Dogecoin address to claim:" extra={}> */}
      <Form.Item
      required
      rules={[
        () => ({
          validator(_, value) {
            return checkDogecoinAddress(value)
          }
        }),
      ]}
      name="dogecoinAddress"
       label="Dogecoin address to claim:" extra={
        (dogeBalance === null || !dogecoinRegex({exact: true}).test(dogecoinAddress)) ? '' : dogeBalanceLoading ? <Skeleton paragraph={false} active={true} /> : 
        dogeBalance === 0 ? 'This Dogecoin address had 0 Doge on block 4,191,928 or has already claimed XDG' : <b style={{fontSize: "1.2em"}}>This address can claim {
          dogeBalance * 1 > 200000 ? 200000 : dogeBalance*1
          } XDG</b>
        }>
        <Search placeholder="Paste your Dogecoin address" loading={dogeBalanceLoading} onChange={(e) => {
          setDogecoinAddress(e.currentTarget.value)
          if (dogecoinRegex({exact: true}).test(e.currentTarget.value)){
            setDogeBalanceLoading(true)
            fetch('https://api.dogenano.io'+ '/get-balance?address='+e.currentTarget.value, {method: 'GET', headers: { 'Accept': 'application/json'}})
        .then((r) => r.json())
        .then((r) => {
            if (r.error !== undefined) {
                    openError(r.error)
              }
              else {
                    setDogeBalance(r.sum)
              }
              setDogeBalanceLoading(false)
          })
          }
        }} 
        />
      </Form.Item>
      <Form.Item 
      name={"dogenanoAddress"}
      required
      rules={[() => ({
          validator(_, value) {
            return checkDogeNanoAddress(value)
          }
        }),
      ]}
label="DogeNano destination address:" extra={<a target={'_blank'} href="https://wallet.dogenano.io">(Get a DogeNano address)</a>}>

        <Input placeholder='Paste your DogeNano address (xdg_)' onChange={(e) => {
          setDogenanoAddress(e.currentTarget.value)
        }}/>


      </Form.Item>
      {
        messageToSign === null ? '' : 
      <Form.Item label="Message to sign:">
      <Paragraph copyable={{text: messageToSign}}  style={{textAlign: "left"}}>
     <pre><code>{messageToSign}</code></pre>
      </Paragraph>
      <div style={{textAlign: "justify"}}>
      Sign the above message with your Dogecoin address, and enter the signature below.<br/> See FAQ.
      </div>
      </Form.Item>
      }
      <Form.Item required label="Signature:" name={"signature"} 
      rules={[() => ({
          validator(_, value) {
            return checkSignature()
          }
        }),
      ]}
       >
      <TextArea placeholder='Paste your message signature' onChange={(e) => {
          setSignature(e.currentTarget.value)

        }}/>
        {isSignatureValid ? <div style={{color: "green"}}>Signature is valid! You can claim. </div>:  ''}
      </Form.Item>
      <Form.Item>

      <Button  size='large' loading={loading} 
       type='primary'  htmlType="submit" >Claim</Button>
      
      </Form.Item>
      </Form>
    }
    </Card>
     
    </>
  )
}

export default Claim