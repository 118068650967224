import { Statistic, Table} from 'antd'

import React, {useState, useEffect} from 'react'



function ClaimTableDiscord() {
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = [
      {
        title: 'Date claim',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (d) => new Date(d).toLocaleDateString() + " " + new Date(d).toLocaleTimeString()

      },
      {
        title: 'Discord username',
        dataIndex: 'discordUsername',
        key: 'discordUsername',
      },
      {
        title: 'Discord Id',
        dataIndex: 'discordId',
        key: 'discordId',
      },
      {
        title: 'XDG Claimed',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'DogeNano Hash',
        dataIndex: 'hashSent',
        key: 'hashSent',
        render: (r) => <a href={'https://explorer.dogenano.io/block/'+r}>View</a>
      },
      {
        title: 'Message claim',
        dataIndex: 'messageClaim',
        key: 'messageClaim',
        render: (r, row) => <a href={'https://discord.com/channels/'+ row.messageGuildId + "/" + row.messageChannelId + "/" + row.messageId}>View</a>
      },
      {
        title: 'Message DCounter verification',
        dataIndex: 'messageAlt',
        key: 'messageAlt',
        render: (r, row) => <a href={'https://discord.com/channels/'+ row.messageVerifiedGuildId + "/" + row.messageVerifiedChannelId + "/" + row.messageVerifiedId}>View</a>
      },
      {
        title: 'DogeNano Address',
        dataIndex: 'dogeNanoAddress',
        key: 'dogeNanoAddress',
      },
    ];
    
    useEffect(() => {
      setLoading(true)
      fetch('https://api.dogenano.io'+ '/get-discord-claims', {method: 'GET', headers: { 'Accept': 'application/json'}})
      .then((r) => r.json())
        .then(r => {
          setResult(r.claims)
          setLoading(false)
        })
        
    }, [])
    
  return (
    <div>
      <div style={{fontSize: "1.35em"}}>{+result.reduce(function(prev, cur) {return prev + cur.amount}, 0)} XDG have been distributed so far through Discord Airdrop</div>
      <Table loading={loading} scroll={{x: true}} dataSource={result} columns={columns} />
    </div>
  )
}

export default ClaimTableDiscord