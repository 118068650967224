import { Card, Collapse, Image, Typography } from 'antd'
import React from 'react'
import dogeNanoDiscord from './img/dogenano-discord.png'
import claimJsTool from './img/claim-with-js-tool.png'
const { Panel } = Collapse;

function FAQ() {
  return (
      <div style={{margin: 16}}>
        <Typography.Title level={2} style={{ color: "#333", marginTop: 32 }}>FAQ</Typography.Title>
        <Typography.Title level={3} style={{ color: "#333" }}>Why a signature is required?</Typography.Title>
        <p>A signature allows to prove that you own this Dogecoin addess and that you requested a claim to a specific DogeNano address, this makes the distribution <a href="/verify">fully transparent and verifyable.</a></p>

        <Typography.Title level={3} style={{ color: "#333" }} id='how-to-sign'>How to get the signature?</Typography.Title>
        <p>
        Here are 3 different methods to sign a Dogecoin message:
</p>
<Collapse defaultActiveKey={[]} style={{textAlign: "left", marginBottom: 16}}>
<Panel  forceRender={true} header="Linux/OS X/Windows - MultiDoge Wallet" key={"multidoge"}>
<p>

Import your private key (WIF Format) into <a target={"_blank"} href='https://multidoge.org/'>MultiDoge open source Wallet</a> and use the following guide:
<Image src='https://pbs.twimg.com/media/FQ04MdKXIAAWhXL?format=png&name=4096x4096'></Image>
Check <a href='https://multidoge.org/help/v0.1/help_importingPrivateKeys.html'>MultiDoge Import private key</a> and  <a href='https://multidoge.org/help/v0.1/help_signAndVerifyMessage.html'>MultiDoge signing tutorial</a> for additional help 
</p>
</Panel>
<Panel  forceRender={true} header="Web - JS Dogecoin signature tool" key={"dogecoin-signature-tool"}>
<p>

 <a target={"_blank"} href='https://github.com/Dogenano-xdg/dogecoin-signature-tool'>Dogecoin Signature Tool</a> is an open source client side app to sign a message from a 12 or 24 words key or from a WIF key. 
 It is recommended to download, verify and exectute source code locally to use it. <br/>
 Beware of phishing. The only official hosted Github page URL is : <a href="https://dogenano-xdg.github.io/dogecoin-signature-tool/">https://dogenano-xdg.github.io/dogecoin-signature-tool/</a> 
</p>
<Image src={claimJsTool}></Image>
  </Panel>
  <Panel  forceRender={true} header="IOS/Android - Coinomi Wallet" key={"Coinomi"}>
  <p>
  <a href="https://www.coinomi.com/">Coinomi</a> is a mobile wallet with an integrated signature function.
Check <a target={"_blank"} href='https://coinomi.freshdesk.com/support/solutions/articles/29000025877-how-to-sign-verify-messages'>Coinomi message signing tutorial</a> <br/>
  If you were not using this wallet, check their guide to migrate your wallet: <a href='https://coinomi.freshdesk.com/support/solutions/articles/29000009716-migrating-from-other-wallets-into-coinomi'>Coinomi migrating guide</a><br/>
  </p>
</Panel>
</Collapse>
<b>Beware</b> : Never enter your seed phrase on an untrusted source. Don't trust, verify. For extra security, withdraw your funds to a new wallet before using any signature tool. <br/>This list is not exhaustive nor endorsed. <br/>
        <Typography.Title level={3} style={{ color: "#333", marginTop: 8 }}>Is there an API to claim?</Typography.Title>
        <p>Yes, Exchanges and anyone can claim programmatically by sending a POST request to https://api.dogenano.io/claim with 
        <pre style={{fontSize: "1em"}}>
        &#123; <br/>
          &nbsp; "dogecoinAddress":"DOGECOIN_ADDRESS", <br/>
          &nbsp; "dogenanoAddress":"DOGENANO_DESTINATION_ADDRESS", <br/>
          &nbsp; "signature": "SIGNED_MESSAGE" <br/>
        &#125;
        </pre>
        in request body. <br/>
        SIGNED_MESSAGE must be the signature of "snapshot 4/20/22 send dogenano to DOGENANO_DESTINATION_ADDRESS" signed from DOGECOIN_ADDRESS. See <a href="https://github.com/Dogenano-xdg/dogecoin-signature-tool/blob/main/index.js#L78">here</a> for an example on how to sign a message from a Dogecoin address in Javascript.
        </p>
        <p>
        https://api.dogenano.io/get-balance?address=DOGECOIN_ADDRESS can be used to get the amount not yet claimed. 
        </p>
        <Typography.Title level={3} style={{ color: "#333" }}>I have other questions, what can I do?</Typography.Title>
        <p style={{paddingBottom: 32}}>Feel free to ask on our <a href="https://discord.gg/B7eDT2NtcK">Discord</a> <img style={{ }} src={dogeNanoDiscord} alt="DogeNano logo" width={"22px"} height={"22px"} /></p>
  </div>
  )
}

export default FAQ