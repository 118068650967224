import React, { useEffect } from 'react'
import { Typography, Card, Divider, Button, Row, Col, Steps, Space, Table, Collapse } from 'antd';
import { CloudDownloadOutlined, GlobalOutlined, MobileOutlined, AndroidOutlined, AppleOutlined, WalletOutlined, SwapOutlined, RedditOutlined, TwitterOutlined, AppleFilled, GithubOutlined } from "@ant-design/icons";
import { DiscordIcon, DiscordIconLarge } from './Components/DiscordIcon';
import Latests from './Latests';
import Stats from './Stats';
import { Link } from "react-router-dom";
import Footer from './Footer';
import dogeNanoDiscord from './img/dogenano-discord.png'
import dogeNanoDiscord2 from './img/dogenano-discord-2.png'
import XDG from './img/XDG.png'
import Reddit from  './img/reddit.png'
const { Step } = Steps;
const { Panel } = Collapse;

const DogecoinIcon = () => <img width={22} style={{ marginRight: 4 }} src="https://cryptologos.cc/logos/dogecoin-doge-logo.svg?v=023"></img>

function Base() {
    useEffect(() => {
        document.title = "DogeNano - A high performance open source crypto distributed to Dogecoin holders"
    }, [])

    const columnsComparaison = [
        {
            title: '',
            dataIndex: 'criteria',
            key: 'criteria'
        },
        {
            title: <><DogecoinIcon />Dogecoin</>,
            dataIndex: 'dogecoin',
            key: 'dogecoin',
        },
        {
            title: <><img style={{ marginRight: 4 }} src={XDG} alt="DogeNano logo" width={"22px"} height={"22px"} />DogeNano</>,
            dataIndex: 'dogenano',
            key: 'dogenano',
        },

    ]

    const Discover = () => {
        return <Card title={<Typography.Title level={2} style={{ fontSize: "1.4em" }}>Discover DogeNano</Typography.Title>}>
    <div>
        <div style={{ marginBottom: 12 }}>
            <Typography.Title id='wallets' level={2} style={{ fontSize: "1.35em", }}><WalletOutlined /> Wallets</Typography.Title>
            <p style={{ marginBottom: 8 }}>Get a DogeNano address:</p>
            <Space wrap>
                <Button size='large' href='https://wallet.dogenano.io/' target='_blank' type='primary' icon={<GlobalOutlined />}>Dault (web)</Button>

                <Button size='large' href='https://play.google.com/store/apps/details?id=co.dogenano.dault' target='_blank' type='default' icon={<AndroidOutlined />}>Kryptos (Android)</Button>
                {/* <Button size='large' href='https://wallet.dogenano.io/' target='_blank' type='default' icon={<AppleOutlined/>}>Kryptos</Button>  */}
            </Space>
        </div>

        <div style={{ marginBottom: 12, marginTop: 24 }}>
            <Typography.Title level={2} style={{ fontSize: "1.35em" }}>⛲ Free Faucets</Typography.Title>
            <p style={{ marginBottom: 8 }}> Get some DogeNano in seconds:</p>
            <Space wrap={true}>
                <Button size='large' href='https://nanswap.com/dogenano-faucet' target='_blank' icon={<CloudDownloadOutlined />}>Nanswap</Button>
                <Button size='large' href='https://discord.gg/B7eDT2NtcK' target='_blank' icon={<DiscordIcon />}>Discord (/faucet)</Button>
                <Button size='large' href='https://getnano.ovh' target='_blank' icon={<CloudDownloadOutlined />}>Getnano.ovh</Button>
            </Space>
        </div>
        <div style={{ marginBottom: 12 }}>
            <Typography.Title level={2} style={{ fontSize: "1.35em", marginTop: 24 }}>🪂 Free Airdrops</Typography.Title>
            <p style={{ marginBottom: 8 }}> Get more DogeNano:</p>
            <Space wrap={true}>
                <Link to="/distribution">
                    <Button size='large' icon={<DogecoinIcon />}>Dogecoin | 1 DOGE = 1 XDG</Button>
                </Link>
                <Button size='large' href='https://discord.gg/B7eDT2NtcK' target='_blank' icon={<DiscordIcon />}>Discord | 10 XDG </Button>
                
                {/* <Button  size='large' icon={<RedditOutlined/>}>Reddit (soon)</Button> */}
            </Space>
        </div>
        <div style={{ marginBottom: 12 }}>
            <Typography.Title level={2} style={{ fontSize: "1.35em", marginTop: 24 }}>💱 Exchange</Typography.Title>
            <p style={{ marginBottom: 4 }}> Get even more DogeNano <img style={{}} src={dogeNanoDiscord} alt="DogeNano swag logo" width={"32px"} height={"32px"} /></p>
            <Button icon={<SwapOutlined />} target='_blank' href='https://nanswap.com/swap/XNO/XDG' size='large'>Nanswap</Button>
        </div>
        <div style={{ marginBottom: 12 }}>
            <Typography.Title level={2} style={{ fontSize: "1.35em", marginTop: 24 }}>🎰 Casino</Typography.Title>
            <p style={{ marginBottom: 4 }}> Gamble your DogeNano <img style={{}} src={dogeNanoDiscord2} alt="DogeNano logo" width={"32px"} height={"32px"} /></p>
            <Button icon={"🎲 "} target='_blank' href='https://nanbet.io' size='large'>NanBet</Button>
        </div>
    </div>
</Card>
    }

    const WhatIs = () => {
        return <Card title={<Typography.Title level={2} style={{ fontSize: "1.4em" }}>What is DogeNano?</Typography.Title>}>
        <p>
            <div style={{ marginBottom: 16 }}>
                DogeNano (XDG) is a digital currency with <span className='csm'>much performances</span> <img style={{}} src={dogeNanoDiscord} alt="DogeNano swag logo" width={"32px"} height={"32px"} /> <br />
                Transactions are <span className='csm'>fast</span>, with <span className='csm'>0 fees</span> and consume significantly <span className='csm'>less energy</span> than PoW.
            </div>
        </p>

        <Collapse defaultActiveKey={[]} ghost style={{ marginTop: 12 }}>
            <Panel header={<> <Typography.Title level={2} style={{ color: "#333", fontSize: "1.25em" }}>What are the differences with Dogecoin?</Typography.Title></>} key="0">
                <Table style={{}} pagination={false} columns={columnsComparaison} dataSource={[
                    {
                        criteria: "Transaction fees",
                        dogecoin: <><span className=''>much</span></>,
                        dogenano: <><span className='csm'>0</span></>,
                    },
                    {
                        criteria: "Transaction speed",
                        dogecoin: <><span className=''></span> slow</>,
                        dogenano: <><span className='csm'>much</span> fast</>
                    },
                    {
                        criteria: "Transactions per second",
                        dogecoin: <><span className=''></span> limited</>,
                        dogenano: <><span className='csm'>much</span> much</>,
                    },
                    {
                        criteria: "Supply",
                        dogecoin: <><span className=''> unlimited</span> </>,
                        dogenano: <><span className='csm'>much</span> limited </>
                    },
                    {
                        criteria: "Energy consumption",
                        dogecoin: <><span className=''>much much much</span></>,
                        dogenano: <><span className='csm'> much </span>lower </>
                    },
                    {
                        criteria: "Data structure",
                        dogecoin: <span className=''>Blockchain</span>,
                        dogenano: <span className='csm'>Block-lattice</span>
                    },
                    {
                        criteria: "Consensus",
                        dogecoin: <><span className=''> PoW</span></>,
                        dogenano: <><span className='csm'> ORV</span></>
                    },
                ]} />
            </Panel>

            <Panel header={<> <Typography.Title level={2} style={{ color: "#333", fontSize: "1.25em" }}>How does DogeNano work?</Typography.Title></>} key="1">
                <p style={{}}>
                    DogeNano is based on a <a href="https://docs.nano.org/glossary/#block-lattice" target={"_blank"}>Block-lattice</a> structure and uses a <a href='https://docs.nano.org/protocol-design/orv-consensus/' target={"_blank"}>ORV</a> consensus. This allows low latency deterministic transactions while consuming about 1,000,000 less energy than PoW.
                    Transactions are fully asynchronous and thanks to a prioritization based on account balance and last time use, DogeNano will always have 0 network fees.<br />
                    Learn more with <a href="https://docs.nano.org/whitepaper/english/">origianl nano Whitepaper</a>
                </p>
            </Panel>
            <Panel header={<><Typography.Title level={2} style={{ color: "#333", fontSize: "1.25em" }}>How did it start?</Typography.Title></>} key="3">
                <p>
                    The project was born from one of the most upvoted issue on the Github of Dogecoin: <a target={"_blank"} href="https://github.com/dogecoin/dogecoin/issues/2024">[feat] Radically improve Dogecoin by forking Nano: Zero fees, fastest cryptocurrency, no inflation, no mining</a>.
                    DogeNano is an independant <a href="https://github.com/Dogenano-xdg/dogenano-node/"> open source </a> project which try to solve this issue.

                </p>
                <div style={{ marginBottom: 8 }}>Credits to <a href='https://github.com/dogecoin/dogecoin/issues/2024#issue-900060239' target={"_blank"}>qwahzi</a>, <a href="https://github.com/dogecoin/dogecoin/issues/2024#issuecomment-847412906" target={"_blank"}>Exxenoz</a> and <a target={"_blank"} href="https://github.com/dogecoin/dogecoin/issues/2024#issuecomment-847417485">bernTOAST</a>.</div>
            </Panel>
            <Panel header={<><Typography.Title level={2} style={{ color: "#333", fontSize: "1.25em" }}>How is it distributed?</Typography.Title></>} key="2">

                <p style={{ marginLeft: 24 }}>
                    <ul>
                        <li>
                            80% of the supply is distributed to Dogecoin holders via <Link scr to={'/distribution'}>airdrop to Dogecoin holders</Link>.<br />
                        </li>
                        <li>
                            10% is freely distributed through Discord, Twitter, Reddit airdrops and used to fund the community fund (hackathon, bug bounty, devs).<br />
                        </li>
                        <li>
                            10% is used as Nanswap Liquidity and will eventually be completely burned or donated to the community fund and will represent 0%.
                        </li>
                    </ul>
                </p>
                <p>At the end of distribution, all the remaining supply not distributed will be burned and exact final supply will be known.</p>
                <p>
                    Distribution aims to be as <a href="https://dogenano.io/verify" target={"_blank"}>transparent and verifiable</a> as possible.
                </p>

            </Panel>

            <Panel header={<><Typography.Title level={2} style={{ color: "#333", fontSize: "1.25em" }}>When will the distribution end?</Typography.Title></>} key="4">
                <p>The distribution is scheduled to end before 04/20/25.</p>
                <p>
                    Check <Link to={'/distribution'}>distribution page</Link> for more details.
                </p>
            </Panel>
        </Collapse>



    </Card>
    }
    return (
        <div style={{ "backgroundColor": "#dcecff", minHeight: "200vh" }}>

            <div style={{ textAlign: "center" }}>
                <img style={{ marginTop: 64 }} src={XDG} alt="DogeNano logo" width={"64"} height={"64"} />
                <Typography.Title level={1} style={{ color: "#333" }}>DogeNano</Typography.Title>
                <p style={{ margin: 8 }}>An open source cryptocurrency distributed to <span className='csm'>Dogecoin</span> holders, with 0 fees and near instant transactions.</p>

            </div>
            <Divider />
            <Row style={{ margin: 8 }}>
                <Col
                    xs={24}
                    sm={24}
                    md={{ offset: 1, span: 22 }}
                    lg={{ offset: 4, span: 16 }}
                    xl={{ offset: 6, span: 12 }}

                >

                    <Discover />
                    <WhatIs />
                    <Latests />
                    <Card title={<Typography.Title level={2} style={{ fontSize: "1.4em" }}>Statistics</Typography.Title>}>
                        <Stats />
                    </Card>
                </Col>
            </Row>
            <div style={{ textAlign: "center", marginTop: 32 }}>
                <Typography.Title level={2} style={{ fontSize: "1.4em" }}>Join the community</Typography.Title>
                <div style={{ marginBottom: 32, display: "flex", justifyContent: "center", gap: 8 }}>
                <a href="https://twitter.com/dogenanoIO" target={"_blank"}>
                    <img alt="X" src='https://abs.twimg.com/favicons/twitter.3.ico' width={24} style={{borderRadius: 4, marginRight: 8}}/>
                </a>
                <a href="https://discord.gg/B7eDT2NtcK" target={"_blank"}><DiscordIconLarge style={{marginRight: 8}} /></a>
                <a href="https://www.reddit.com/r/DogeNano/" target={"_blank"}>
                    <img alt="reddit" src={Reddit} width={24} style={{borderRadius: 4, marginRight: 4, marginLeft: 4}}/>
                </a>
                <a href="https://github.com/Dogenano-xdg/dogenano-node/" style={{marginLeft: 0}}>
                        <img style={{marginRight: 8, fontSize: "2em", color: "#333"}} src="https://img.shields.io/github/stars/dogenano-xdg/dogenano-node?style=social" alt="Github stars" />
                </a>
                

                </div>

                <Divider />

            </div>
            <div style={{ textAlign: "center", marginTop: 0 }}>
                <Footer />
                <small>DogeNano is an independant project, not affiliated with either the Nano or the Dogecoin Foundation.</small>
                <div style={{marginTop: "42px"}}>
                    <img src={dogeNanoDiscord2} alt="DogeNano logo" width={"24px"} height={"24px"} />
                </div>
            </div>
            <div style={{ height: 16 }}> </div>
        </div>
    )
}

export default Base
