import React, { Component, useEffect } from 'react';
import './index.css';
import Countdown from './Countdown'
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ClaimTable from './ClaimTable';
import Base from './Base';
import Verify from './Verify';

 function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class App extends Component {
  render() {
    return (
      
      <BrowserRouter>
            <ScrollToTop />

      <Routes>
        <Route path="/" element={<Base />} />
        <Route path="/distribution" element={<Countdown />} />
        <Route path="/signatures" element={<Navigate replace to={"/verify"} />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/base" element={<Base />} />
      </Routes>
    </BrowserRouter>
    );
  }
}

export default App;
