import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import FlipCountdown from '@rumess/react-flip-countdown';
import logoNanodoge from './img/dogenano.png'
import logoTwitter from './img/twitter-icon.svg'
import dogenanoAirdrop from './img/dogenano-airdrop.png'
import discord from './img/discord.png'
import './App.css';
import { Button, Card, Input, Collapse , Form, Tabs, Divider} from 'antd';
import {
  DoubleRightOutlined
} from '@ant-design/icons';
import React, { Component }  from 'react';
import Claim from './Claim';
import ClaimTable from './ClaimTable';
import FAQ from './FAQ';
import Stats from './Stats';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import dogeNanoDiscord from './img/dogenano-discord.png'

function Countdown() {
  useEffect(() => {
    document.title = "DogeNano - Airdrop to Dogecoin holders"
  }, [])

  const { TabPane } = Tabs;


  return (
    <div className="App" style={{backgroundColor: "#dcecff"}}>
    <div >
    <div style={{textAlign: "center"}}>
    <Link to={'/'}><img src={dogenanoAirdrop} alt="Discord" width={128} style={{marginTop: 48, marginBottom: 32}} /></Link>
    </div>
        {/* <h1 style={{paddingTop: 64, color: "black", textAlign: "center"}}></h1> */}
        <div style={{maxWidth: "600px", marginRight: "auto", marginLeft: "auto"}}>
<Claim />
<Card style={{marginTop: 16}} title="Distribution schedule">
<div>
In order to increase decentralization, the distribution will be updated as follows:
  <ul>
    <li>4/20/2022 4:20:00 PM UTC - 1 DOGE = 10 XDG / Claim not capped</li>
    <li>3/20/2023 4:20:00 PM UTC - 1 DOGE = 10 XDG / claim capped at 2,000,000 XDG</li>
    <li>4/20/2023 4:20:00 PM UTC - 1 DOGE = 5  XDG / Claim capped at 1,000,000 XDG per address</li>
    <li><b>4/20/2024 4:20:00 PM UTC - 1 DOGE = 1  XDG / Claim capped at 200,000 XDG</b></li>
    <div style={{ width: "100%", marginTop: 12, marginBottom: 32}}>
      <FlipCountdown size={"small"} endAt={'2025-04-20 16:20:00'} hideYear />
    </div>
    <li>4/20/2025 4:20:00 PM UTC - End, final burn, all xdg remaining burned</li>
  </ul>
</div>
{/* <div style={{}}>
    Time before next step:
</div> */}
<div style={{marginTop: 16}}>
  Dates are subject to change and may be moved forward if there is strong demand. Join our <a href="https://discord.gg/B7eDT2NtcK">Discord</a> to keep up to date. <img style={{ }} src={dogeNanoDiscord} alt="DogeNano logo" width={"22px"} height={"22px"} />
</div>
</Card>

<Divider />
<FAQ />
  {/* <Stats /> */}
 
    </div>
    </div>
    <Divider />
<Footer />
    </div>
  );
}

export default Countdown;
