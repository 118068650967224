import {Tabs, Typography} from 'antd'

import React, {useState, useEffect} from 'react'
import ClaimTable from './ClaimTable';
import ClaimTableDiscord from './ClaimTableDiscord';
const { Title, Paragraph, Text, Link } = Typography;

function Verify() {
  const [key, setKey] = useState('dogecoin')
    useEffect(() => {
      if (window.location.hash === "#discord"){
        setKey('discord')
      }
      
    }, [])
    
  return (
    <div style={{margin: 16}}>
    <Title>Distribution</Title>
    <Paragraph style={{fontSize: "1.35em"}}>
    All the distribution claims are public and recorded to make distribution as transaparent and verifiable as possible. <br/>
    Distribution data (JSON) can be downloaded with <a href="https://api.dogenano.io/get-claims">api.dogenano.io/get-claims</a> and <a href="https://api.dogenano.io/get-discord-claims">api.dogenano.io/get-discord-claims</a>

    <Title level={2} style={{marginTop: 16, marginBottom: 8}}>How to verify</Title>
      <Title level={3} style={{marginTop: 16, marginBottom: 8}}>Distribution to dogecoin holders (>80% supply)</Title>
      <ol>
        <li>
        Verify message and signature below (eg: with MultiDoge Wallet or <a href='https://github.com/Dogenano-xdg/dogecoin-signature-tool'>Dogecoin signature tool</a>) 
        </li>
        <li>
        Verify Dogecoin Address Balance at block 4,191,928 (eg: With <a href='https://dogechain.info/' target={"_blank"}>dogechain.info</a>)
        </li>
        <li>
            <ul>
              <li>
                  For claims before 4/20/2023 4:20:00 PM UTC, XDG sent must be equal to 10x Dogecoin Balance at block 4,191,928
              </li>
              <li>
                  For claims after 4/20/2024 4:20:00 PM UTC, XDG sent must be equal to 5x Dogecoin Balance at block 4,191,928, and should be capped at 1,000,000 XDG per claim.
              </li>
            </ul>
        </li>
      </ol> 
      <Title level={3} style={{marginTop: 16, marginBottom: 8}}> Discord Airdrops (&lt;10% supply)</Title>
      Airdrop #1: <br/>
        420 XDG has been sent to the first 1000 claimers on <a href="https://discord.gg/B7eDT2NtcK">Discord</a>. <br/>
        <ol style={{marginBottom: 4}}>
          <li>Discord Account must be created before 4/20/22</li>
          <li>Discord Account must be verified by third party <a href='https://dcounter.space/' target={"_blank"}>dcounter</a> </li>
          <li>Discord User Id, Discord claim message id and discord verification message id are shown in the table below and can be viewed on Discord. </li>
        </ol>
      Airdrop #2 (ongoing): <br/>
        100 XDG has been sent to the first 4200 claimers on <a href="https://discord.gg/B7eDT2NtcK">Discord</a>. <br/>
        <ol style={{marginBottom: 4}}>
          <li>Discord Account must be created before 01/09/22</li>
          <li>Discord Account must be verified by third party <a href='https://dcounter.space/' target={"_blank"}>dcounter</a> </li>
          <li>Discord User Id, Discord claim message id and discord verification message id are shown in the table below and can be viewed on Discord. </li>
        </ol>
    <br/>
    </Paragraph>
    <div style={{fontSize: "1.35em"}}>

    <Tabs defaultActiveKey="1" activeKey={key} onChange={(key) => setKey(key)}>
    <Tabs.TabPane tab="Dogecoin holders Airdrop" key="dogecoin">
      <ClaimTable />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Discord Airdrop" key="discord">
      <ClaimTableDiscord />
    </Tabs.TabPane>
    </Tabs>
    </div>
    </div>
  )
}

export default Verify