import React from 'react'

function Footer() {
  return (
    <div style={{marginTop: 8, paddingBottom: 16, textAlign: "center"}}>
    DogeNano © 2023
</div>
  )
}

export default Footer