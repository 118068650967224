import { Statistic, Table, Tabs} from 'antd'

import React, {useState, useEffect} from 'react'
import ClaimTableDiscord from './ClaimTableDiscord';



function ClaimTable() {
    const [result, setResult] = useState([])
    const [sum, setSums] = useState({sumDogenano: 0, sumDogecoin: 0})
    const [loading, setLoading] = useState(false)

    const columns = [
      {
        title: 'Date claim',
        dataIndex: 'claimDate',
        key: 'claimDate',
        render: (d) => new Date(d).toLocaleDateString() + " " + new Date(d).toLocaleTimeString()

      },
      {
        title: 'Dogecoin Address',
        dataIndex: 'dogecoinAddress',
        key: 'dogecoinAddress',
      },
      {
        title: 'DogeNano Address',
        dataIndex: 'dogenanoAddress',
        key: 'dogenanoAddress',
      },
   
      {
        title: 'XDG Claimed',
        dataIndex: 'dogenanoAmount',
        key: 'dogenanoAmount',
      },
      {
        title: 'DogeNano Hash',
        dataIndex: 'hashSent',
        key: 'hashSent',
        render: (r) => <a href={'https://explorer.dogenano.io/block/'+r}>View</a>
      },
      {
        title: 'Message signed',
        dataIndex: 'message',
        key: 'message',
      },
      {
        title: 'Signature',
        dataIndex: 'signature',
        key: 'signature',
      },
    ];

    useEffect(() => {
      setLoading(true)
      fetch('https://api.dogenano.io'+ '/get-claims', {method: 'GET', headers: { 'Accept': 'application/json'}})
        .then((r) => r.json())
        .then(r => {
          setResult(r.claims)
          setSums(r.sum)
          setLoading(false)
        })
        
    }, [])
    
  return (
    <div>
    <div style={{fontSize: "1.35em"}}>{+sum.sumDogenano.toPrecision(10)} XDG have been distributed so far through Dogecoin Holders Distribution</div>
      <Table loading={loading} scroll={{x: true}} dataSource={result} columns={columns} />
    </div>
  )
}

export default ClaimTable