import React, { useEffect, useState } from 'react'
import { Avatar, Select, List, Typography, Card, Divider, Button, Row, Col, Steps, Space, Table } from 'antd';
import ReactTimeAgo from 'react-time-ago'
import {FilterOutlined  } from "@ant-design/icons";
import { Link } from 'react-router-dom';

function getColumns() {
    return [

        {
            title: "Address", 
            dataIndex: "address",
            render: (address, row) => 
            <a target={"_blank"} href={'https://explorer.dogenano.io/block/' + row.hash}>
                <img alt="address-logo" width={48} src={"https://doggycon.dogenano.io/api/v1/pilou/" + address} />
            </a>
        },
        {
            title: "Amount", 
            dataIndex: "amountSent",
            render: (amountSent) => parseFloat(amountSent.toPrecision(7 )) + " XDG",
            sorter: (a, b) => a.amountSent - b.amountSent,
        },
        
        {
            title: "Date", 
            dataIndex: "createdAt",
            render: (createdAt) =>  <ReactTimeAgo date={createdAt} locale="en-US" />,
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt) ,


        },
        {
            title: "Hash", 
            dataIndex: "hash",
            render: (hash) => <a target={"_blank"} href={'https://explorer.dogenano.io/block/' + hash}>{hash.slice(0, 4) + "..."}</a>

        }
      ];
}
function Latests() {
        const [dataFaucet, setDataFaucet] = useState(null)
        const [dataClaims , setDataClaims] = useState(null)
        const [data, setData] = useState(null)
        useEffect( () => {
            (async () => {

            
            let [claims] = await Promise.all([
                // fetch('https://data.nanswap.com/get-last-claims?ticker=XDG').then(d => d.json()),
                fetch('https://api.dogenano.io/get-claims').then(d => d.json())
            ])

            let all = []
            let parsed = claims.claims.map((claim) => {
                return {
                    "hash": claim.hashSent,
                    "amountSent": claim.dogenanoAmount,
                    "address": claim.dogenanoAddress,
                    "createdAt": claim.claimDate,
                    "type": "Doge Airdrop"
                }
            })
            // let parsedFaucet = faucets.map((claim) => {
                // return {
                    // "hash": claim.hash,
                    // "amountSent": claim.amountSent,
                    // "address": claim.address,
                    // "createdAt": claim.createdAt,
                    // "type": "Faucet"
                // }
            // })
            // all = [...parsed, ...parsedFaucet]
            // all.sort((a,b) =>  new Date(b.createdAt) - new Date(a.createdAt));
            // console.log({all});
            // setDataFaucet(parsedFaucet)
            setDataClaims(parsed)
           //let interval = setInterval(() => {
           //    fetch('https://data.nanswap.com/get-last-claims?ticker=XDG').then(d => d.json()).then((d) => setDataFaucet(d))
           //}, 1000 * 10);
            
            //return () => {
            //    clearInterval(interval);
            //  };

        })();
        }, [])
    
        if (dataClaims === null) {
            return (
                <Card title={"Latest DogeNano claims"}>
                    <div style={{ height: "350px", overflow: "auto" }}>
                        <List
                            loading={true}
                            itemLayout="horizontal"
                        />
                    </div>
                </Card>
            )
        }
        return (
            <Card 
            title={<Typography.Title level={2} style={{ fontSize: "1.4em" }}>Latest DogeNano claims</Typography.Title>}
            >
        
                <div style={{  overflow: "auto" }}>
    
                    <Table
                    size='small'
                    columns={getColumns('Doge Airdrop')}
                    loading={dataClaims === null}
                    itemLayout="horizontal"
                    dataSource={dataClaims}
                    pagination={{defaultPageSize: 5}}
                         />
                </div>
                <div style={{ fontSize: "1.2em" }}>
                <ul>
                    <li>
                        Check <Link to="/distribution">Distribution</Link> to claim your DogeNano.
                    </li>
                    <li>
                         Check <Link to="/verify">Verify</Link> to verify the distribution.
                    </li>
                </ul>
                </div>
            </Card>
        )
}

export default Latests